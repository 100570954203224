import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import {
  Button,
  Modal,
  Form,
  FloatingLabel,
  Row,
  Col,
  Tabs,
  Tab,
  Table,
} from "react-bootstrap";
import BaseAPI from "../../../api/BaseAPI";
import EditIcon from "@mui/icons-material/Edit";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import InputMask from "react-input-mask";

function ModalEditarEmpresa(props) {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [empresa, setEmpresa] = useState([{}]);
  const [parceiros, setParceiros] = useState([{}]);
  const [produtosVinculados, setProdutosVinculados] = useState([]);
  const [key, setKey] = useState("editarEmpresa");
  const [produtos, setProdutos] = useState([]);
  const [produtoSelecionado, setProdutoSelecionado] = useState("");
  const [produtoARemover, setProdutoARemover] = useState([]);

  const {
    handleSubmit,
    formState: { errors },
    setValue,
    getValues,
  } = useForm();

  const customToastOptions = {
    position: "bottom-right", // Posição onde as notificações serão exibidas
    autoClose: 3000, // Tempo em milissegundos para as notificações fecharem automaticamente
    hideProgressBar: false, // Mostrar barra de progresso de tempo
    pauseOnHover: true, // Pausar o tempo de fechamento ao passar o mouse sobre a notificação
    draggable: true, // Permitir arrastar as notificações
    progress: undefined, // Componente customizado para barra de progresso, caso queira substituir
  };

  const editar = () => {
    BaseAPI.patch("/clientes/empresa/" + props.idEmpresa + "/", empresa)
      .then((response) => {
        props.getEmpresas();
        editarProdutoCliente();
        handleClose();
        toast.success("Empresa editada!", customToastOptions);
      })
      .catch((err) => {
        toast.error("Erro ao editar empresa!", customToastOptions);
      });
  };

  function getEmpresa() {
    handleShow();
    BaseAPI.get("/clientes/empresa/" + props.idEmpresa)
      .then((response) => {
        const { data } = response;
        setEmpresa(data);
        console.log(empresa);
      })
      .catch((err) => {
        alert(err);
      });
  }

  const [cep, setCep] = useState("");

  const buscaCep = (event) => {
    const novoCep = event.target.value;
    setCep(novoCep);
    if (novoCep.length === 8) {
      axios
        .get(`https://viacep.com.br/ws/${novoCep}/json`)
        .then((response) => {
          setEmpresa({
            ...empresa,
            endereco_cep: novoCep,
            endereco_rua: response.data.logradouro,
            endereco_bairro: response.data.bairro,
            endereco_cidade: response.data.localidade,
            endereco_uf: response.data.uf,
          });
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const getParceiros = () => {
    handleShow();
    BaseAPI.get("/parceiros/select_parceiros/")
      .then((response) => {
        const { data } = response;
        setParceiros(data);
      })
      .catch((err) => {
        alert(err);
      });
  };

  const getProdutosVinculados = () => {
    BaseAPI.get("/produtos/produto_cliente/", {
      params: {
        id_empresa: props.idEmpresa,
      },
    })
      .then((response) => {
        const { data } = response;
        setProdutosVinculados(data.results);
      })
      .catch((err) => {
        alert(err);
      });
  };

  const getProdutos = () => {
    BaseAPI.get("/produtos/select_produtos/")
      .then((response) => {
        const { data } = response;
        setProdutos(data);
      })
      .catch((err) => {
        alert(err);
      });
  };

  const adicionarProdutoClienteArray = () => {
    debugger
    const produtoSelecionadoObj = produtos.find(
      (produto) => produto.value === parseInt(produtoSelecionado)
    );

    if (!produtoSelecionado) {
      toast.error("Selecione um produto!", customToastOptions);
    } else if (
      produtosVinculados.some(
        (produto) => produto.produto === parseInt(produtoSelecionado)
      )
    ) {
      toast.error("Produto já foi adicionado!", customToastOptions);
    } else {
      setProdutosVinculados([
        ...produtosVinculados,
        { produto: parseInt(produtoSelecionado), nome: produtoSelecionadoObj.nome, status: produtoSelecionadoObj.status},
      ]);
      toast.success("Produto adicionado!", customToastOptions);
    }
  };

  const removerProdutoClienteArray = (id) => {
    setProdutosVinculados((prevProdutos) =>
      prevProdutos.filter((produto) => produto.produto !== id)
    );
    setProdutoARemover([...produtoARemover, { id: parseInt(id) }]);
  };

  const editarProdutoCliente = () => {
    const produtos = produtosVinculados.map((produto) => ({
      id: produto.produto,
      status: produto.status,
    }));
    BaseAPI.post("/produtos/editar_produto_cliente/" + props.idEmpresa + "/", {
      produtos: produtos,
      aRemover: produtoARemover,
      empresa: "empresa",
    })
      .then((response) => {})
      .catch((err) => {
        alert(err);
      });
  };

  useEffect(() => {
    if (show) {
      getParceiros();
      getProdutosVinculados();
      getProdutos();
    }
  }, [show]);
  return (
    <>
      <Button
        variant="warning"
        onClick={() => getEmpresa()}
        title="Abrir cadastro do empresa"
        className="p-1"
      >
        <EditIcon />
      </Button>
      <Modal show={show} onHide={handleClose} centered size="xl">
        <Modal.Header closeButton>
          <Modal.Title>Cadastro da empresa </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Tabs
            id="controlled-tab"
            activeKey={key}
            onSelect={(k) => setKey(k)}
            className="mb-3"
          >
            <Tab eventKey="editarEmpresa" title="Cadastro">
          <Form onSubmit={handleSubmit(editar)}>
            <Row>
              <Col>
                <FloatingLabel
                  controlId="floatingInput"
                  label="Nome*"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    maxLength={100}
                    required
                    value={empresa.nome}
                    onChange={(e) =>
                      setEmpresa({ ...empresa, nome: e.target.value })
                    }
                  />
                </FloatingLabel>
              </Col>
              <Col>
                <FloatingLabel
                  controlId="floatingInput"
                  label="CNPJ*"
                  className="mb-3"
                  required
                >
                  <InputMask
                    mask="99.999.999/9999-99"
                    maskChar="_"
                    value={empresa.cnpj}
                    onChange={({target}) => setEmpresa({ ...empresa, cnpj: target.value })}
                  >
                    {() => (
                      <Form.Control
                        type="text"
                      />
                    )}
                  </InputMask>
                </FloatingLabel>
              </Col>
            </Row>
            <Row>
              <Col>
                <FloatingLabel
                  controlId="floatingInput"
                  label="Telefone*"
                  className="mb-3"
                  required
                >
                  <Form.Control
                    type="text"
                    maxLength={20}
                    value={empresa.telefone}
                    onChange={(e) =>
                      setEmpresa({
                        ...empresa,
                        telefone: e.target.value,
                      })
                    }
                  />
                </FloatingLabel>
              </Col>
              <Col>
                <FloatingLabel
                  controlId="floatingInput"
                  label="E-mail*"
                  className="mb-3"
                  required
                >
                  <Form.Control
                    type="text"
                    maxLength={100}
                    value={empresa.email}
                    onChange={(e) =>
                      setEmpresa({
                        ...empresa,
                        email: e.target.value,
                      })
                    }
                  />
                </FloatingLabel>
              </Col>
            </Row>
            <Row>
              <Col>
                <FloatingLabel
                  controlId="cepInput"
                  label="CEP"
                  htmlFor="cepInput"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    maxLength={8}
                    value={empresa.endereco_cep}
                    onChange={(e) => {
                      setEmpresa({
                        ...empresa,
                        endereco_cep: e.target.value,
                      });
                      buscaCep(e);
                    }}
                  />
                </FloatingLabel>
              </Col>
              <Col>
                <FloatingLabel
                  controlId="floatingInput"
                  label="Rua"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    maxLength={256}
                    value={empresa.endereco_rua}
                    onChange={(e) =>
                      setEmpresa({
                        ...empresa,
                        endereco_rua: e.target.value,
                      })
                    }
                  />
                </FloatingLabel>
              </Col>
              <Col>
                <FloatingLabel
                  controlId="floatingInput"
                  label="N°"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    maxLength={10}
                    value={empresa.endereco_numero}
                    onChange={(e) =>
                      setEmpresa({
                        ...empresa,
                        endereco_numero: e.target.value,
                      })
                    }
                  />
                </FloatingLabel>
              </Col>
            </Row>
            <Row>
              <Col>
                <FloatingLabel
                  controlId="floatingInput"f
                  label="Bairro"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    maxLength={256}
                    value={empresa.endereco_bairro}
                    onChange={(e) =>
                      setEmpresa({
                        ...empresa,
                        endereco_bairro: e.target.value,
                      })
                    }
                  />
                </FloatingLabel>
              </Col>
              <Col>
                <FloatingLabel
                  controlId="floatingInput"
                  label="Cidade"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    maxLength={256}
                    value={empresa.endereco_cidade}
                    onChange={(e) =>
                      setEmpresa({
                        ...empresa,
                        endereco_cidade: e.target.value,
                      })
                    }
                  />
                </FloatingLabel>
              </Col>
              <Col>
                <FloatingLabel
                  controlId="floatingInput"
                  label="UF"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    maxLength={2}
                    value={empresa.endereco_uf}
                    onChange={(e) =>
                      setEmpresa({
                        ...empresa,
                        endereco_uf: e.target.value,
                      })
                    }
                  />
                </FloatingLabel>
              </Col>
            </Row>
            <Row>
              <Col>
                <FloatingLabel
                  controlId="floatingInput"
                  label="Descrição"
                  className="mb-3"
                  required
                >
                  <Form.Control
                    type="text"
                    value={empresa.descricao}
                    onChange={(e) =>
                      setEmpresa({
                        ...empresa,
                        descricao: e.target.value,
                      })
                    }
                  />
                </FloatingLabel>
              </Col>
              <Col>
                <FloatingLabel
                  controlId="parceiro"
                  label="Parceiro"
                  className="mb-3"
                >
                  <Form.Select
                    type="text"
                    value={empresa.parceiro}
                    onChange={(e) =>
                      setEmpresa({
                        ...empresa,
                        parceiro: e.target.value,
                      })
                    }
                  >
                    <option value="">Selecione...</option>

                        {parceiros.length > 0 &&
                          parceiros.map((parceiro) => {
                            return (
                              <option
                                key={parceiro.value}
                                value={parceiro.value}
                              >
                                {parceiro.label}
                              </option>
                            );
                          })}
                      </Form.Select>
                    </FloatingLabel>
                  </Col>
                </Row>
              </Form>
            </Tab>
            <Tab eventKey="produtosPessoa" title="Produtos">
              <Form>
                <Row>
                  <Col md={10}>
                    <FloatingLabel
                      controlId="produtos"
                      label="Produtos"
                      className="mb-3"
                    >
                      <Form.Select
                        aria-label="Produtos"
                        value={produtoSelecionado}
                        onChange={(e) => setProdutoSelecionado(e.target.value)}
                      >
                        <option value="">Selecione...</option>
                        {produtos.length > 0 &&
                          produtos.map((produto) => {
                            return (
                              <option
                                key={produto.value}
                                value={produto.value}
                                name={produto.label}
                                id={"produto_" + produto.value}
                              >
                                {produto.label}
                              </option>
                            );
                          })}
                      </Form.Select>
                    </FloatingLabel>
                  </Col>
                  <Col className="pt-2">
                    <Button onClick={() => adicionarProdutoClienteArray()}>
                      Adicionar Produto
                    </Button>
                  </Col>
                </Row>
              </Form>
              <p className="text-danger">
                Os dados só serão salvos após clicar em Editar
              </p>
              <Table striped responsive bordered>
                <thead>
                  <tr className="text-center">
                    <th>Produto</th>
                    <th>Status</th>
                    <th>Ação</th>
                  </tr>
                </thead>
                <tbody className="text-center">
                  {produtosVinculados.length > 0 &&
                    produtosVinculados.map((produtoVinculado, index) => {
                      const produto = produtos.find(
                        (p) => p.value === produtoVinculado.produto
                      );

                      return (
                        <tr key={index} value={produtoVinculado.id} className="align-middle">
                          <td value={produtoVinculado.id}>
                            {produto ? produto.label : "Produto não encontrado"}
                          </td>
                          <td>
                            <FloatingLabel
                              controlId={`status_${index}`}
                              label="Status"
                            >
                              <Form.Select
                                aria-label="Status"
                                key={produtosVinculados.id}
                                value={produtoVinculado.status}
                                onChange={(e) => {
                                  const newProdutosVinculados = [
                                    ...produtosVinculados,
                                  ];
                                  newProdutosVinculados[index].status =
                                    e.target.value;
                                  setProdutosVinculados(newProdutosVinculados);
                                }}
                              >
                                <option value="A">Ativo</option>
                                <option value="C">Cancelado</option>
                                <option value="V">Vencido</option>
                              </Form.Select>
                            </FloatingLabel>
                          </td>
                          <td>
                            <Button
                              variant="danger"
                              onClick={() =>
                                removerProdutoClienteArray(
                                  produtoVinculado.produto
                                )
                              }
                            >
                              Remover
                            </Button>
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </Table>
            </Tab>
          </Tabs>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="danger" onClick={handleClose}>
            Cancelar
          </Button>
          <Button variant="success" onClick={handleSubmit(editar)}>
            Editar
          </Button>
        </Modal.Footer>
      </Modal>
      <ToastContainer />
    </>
  );
}

export { ModalEditarEmpresa };
export default ModalEditarEmpresa;
