import React, {useEffect} from 'react'
import { Navigate } from 'react-router-dom'
import {usePermissions} from '../hooks/UsePermissions'


function PermissionRoute({ children }) {
    
    const {isAdmin} = usePermissions()

    useEffect(() => {
        if (!isAdmin) {
           alert("É necessário ser um administrador do sistema para ver esta tela.")
        }
    }, [!isAdmin])

    if (!isAdmin) {
        return (
            <>
                <Navigate to="/dashboard" />
            </>
        )
    }

    return children
}

export {PermissionRoute}
export default PermissionRoute