import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import "bootstrap/dist/css/bootstrap.min.css";
import PrivateRoute from "./navigation/PrivateRoute";
import PermissionRoute from "./navigation/PermissionRoute";
import { Login } from "./autenticacao/pages";
import { Dashboard } from "./dashboard/pages";
import { ListaPessoas } from "./modulos/pessoas/pages/ListaPessoas";
import { ListaProdutos } from "./modulos/produtos/pages/ListaProdutos";
import { MainProvider } from "./context/MainContext";
import Dev from "./Dev";
import App from "./App";
import history from "./navigation/history";
import { createBrowserRouter, RouterProvider } from "react-router-dom";

import ListaUsuarios from "./modulos/usuarios/pages/ListaUsuarios";
import ListaNegocios from "./modulos/negocios/pages/ListaNegocios";
import ListaEmpresas from "./modulos/empresas/pages/ListaEmpresas";
import ListaParceiros from "./modulos/parceiros/pages/ListaParceiros";
import ListaRelatorios from "./modulos/relatorios/pages/ListaRelatorios";
import RelatorioRelacaoClientes from "./modulos/relatorios/pages/RelatorioRelacaoClientes";
import ListagemProdutoCliente from "./modulos/relatorios/pages/ListagemProdutoCliente";
import VendasPeriodo from "./modulos/relatorios/pages/VendasPeriodo";
import ClientesPorProduto from "./modulos/relatorios/pages/ClientesPorProduto";



const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
    children: [
      {
        path: "/",
        element: (
          <PrivateRoute>
            <Dashboard />
          </PrivateRoute>
        ),
      },
      {
        path: "/dashboard",
        element: (
          <PrivateRoute>
            <Dashboard />
          </PrivateRoute>
        ),
      },
      {
        path: "/pessoas",
        element: (
          <PrivateRoute>
            <ListaPessoas />
          </PrivateRoute>
        ),
      },
      {
        path: "/empresas",
        element: (
          <PrivateRoute>
            <ListaEmpresas />
          </PrivateRoute>
        ),
      },
      {
        path: "/produtos",
        element: (
          <PermissionRoute>
            <PrivateRoute>
              <ListaProdutos />
            </PrivateRoute>
          </PermissionRoute>
        ),
      },
      {
        path: "/usuarios",
        element: (
          <PermissionRoute>
          <PrivateRoute>
            <ListaUsuarios />
          </PrivateRoute>
          </PermissionRoute>
        ),
      },
      {
        path: "/negocios",
        element: (
          <PermissionRoute>
            <PrivateRoute>
              <ListaNegocios />
            </PrivateRoute>
          </PermissionRoute>

        ),
      },
      {
        path: "/parceiros",
        element: (
          <PermissionRoute>
            <PrivateRoute>
              <ListaParceiros />
            </PrivateRoute>
          </PermissionRoute>
        ),
      },
      {
        path: "/relatorios",
        element: (
          <PermissionRoute>
            <PrivateRoute>
              <ListaRelatorios />
            </PrivateRoute>
          </PermissionRoute>
        ),
      },
      {
        path: "/relatorios/relacao_clientes",
        element: (
          <PermissionRoute>
            <PrivateRoute>
              <RelatorioRelacaoClientes />
            </PrivateRoute>
          </PermissionRoute>
        ),
      },
      {
        path: "/relatorios/listagem_produto_cliente",
        element: (
          <PermissionRoute>
            <PrivateRoute>
              <ListagemProdutoCliente />
            </PrivateRoute>
          </PermissionRoute>
        ),
      },
      {
        path: "/relatorios/vendas_por_periodo",
        element: (
          <PermissionRoute>
            <PrivateRoute>
              <VendasPeriodo />
            </PrivateRoute>
          </PermissionRoute>
        ),
      },
      {
        path: "/relatorios/clientes_por_produto",
        element: (
          <PermissionRoute>
            <PrivateRoute>
              <ClientesPorProduto />
            </PrivateRoute>
          </PermissionRoute>
        ),
      },
    ],
  },
  {
    path: "login",
    element: <Login />,
  },
  {
    path: "dev",
    element: <Dev />,
  },
]);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <MainProvider>
    <RouterProvider router={router} history={history} />
  </MainProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
