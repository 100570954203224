import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import {
  Breadcrumb,
  Button,
  Container,
  Row,
  Col,
  Form,
  FloatingLabel,
} from "react-bootstrap";
import Select from 'react-select'
import { InputLabel } from '@mui/material';


import BaseAPI from "../../../api/BaseAPI";
import LocalPrintshopIcon from "@mui/icons-material/LocalPrintshop";

function RelatorioRelacaoClientes() {
  const [carregando, setCarregando] = useState(true);
  const [nomeRelatorio, setNomeRelatorio] = useState("");
  const [parceiro, setParceiro] = useState("");
  const [parceiros, setParceiros] = useState("");
  const [dataInicio, setDataInicio] = useState(null);
  const [dataFim, setDataFim] = useState(null);



  const { handleSubmit } = useForm({
    defaultValues: {
      query: "",
    },
  });

  const getRelatorio = () => {
    setCarregando(true);
    BaseAPI.get(`/relatorios/${nomeRelatorio}/`, {
      params: { dataInicio, dataFim, parceiro},
    })
      .then((response) => {
        setCarregando(false);
        window.open(response.request.responseURL, "_blank");
      })
      .catch((err) => {
        alert(err);
      });
  };

  const nomeDoRelatorio = () => {
    const urlAtual = new URL(window.location.href);
    const nomeDoRelatorio = urlAtual.pathname.substring(12);
    setNomeRelatorio(nomeDoRelatorio);

    return nomeDoRelatorio;
  };

  const getParceiros = () => {
    BaseAPI.get("/parceiros/select_parceiros/")
      .then((response) => {
        const { data } = response;
        setParceiros(data);
      })
      .catch((err) => {
        alert(err);
      });
  };

  useEffect(() => {
    nomeDoRelatorio();
    getParceiros()
  }, []);

  return (
    <Container>
      <Breadcrumb>
        <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
        <Breadcrumb.Item href="/relatorios">Relatórios</Breadcrumb.Item>
        <Breadcrumb.Item active>Vendas por período</Breadcrumb.Item>
      </Breadcrumb>
      <form onSubmit={handleSubmit(getRelatorio)}>
        <Row className="my-4">
          <Col sm>
            <FloatingLabel
              controlId="floatingInput"
              label="Data inicio"
              className="mb-3"
            >
              <Form.Control
                type="date"
                value={dataInicio}
                onChange={(e) => setDataInicio(e.target.value)}
              />
            </FloatingLabel>
          </Col>
          <Col sm>
          <FloatingLabel
              controlId="floatingInput"
              label="Data fim"
              className="mb-3"
            >
              <Form.Control
                type="date"
                value={dataFim}
                onChange={(e) => setDataFim(e.target.value)}
              />
            </FloatingLabel>
          </Col>
          <Col sm>
            <InputLabel htmlFor="parceiro">Cliente*</InputLabel>
              <Select 
                inputId="parceiro" 
                options={parceiros}
                name="parceiro"
                placeholder="Selecione..."
                onChange={(e) => {
                  debugger
                  setParceiro(e.value)
                }}
              />
          </Col>
          <Col sm className="mt-2">
            <Button className="mx-1" onClick={handleSubmit(getRelatorio)}>
              <LocalPrintshopIcon />
            </Button>
          </Col>
        </Row>
      </form>
    </Container>
  );
}

export { RelatorioRelacaoClientes };
export default RelatorioRelacaoClientes;
