import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import {
  Button,
  Modal,
  Form,
  FloatingLabel,
  Row,
  Col,
  Tabs,
  Tab,
  Table,
} from "react-bootstrap";
import BaseAPI from "../../../api/BaseAPI";
import EditIcon from "@mui/icons-material/Edit";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import InputMask from "react-input-mask";
import Select from 'react-select'
import { find } from "lodash";


function ModalEditarPessoa(props) {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [pessoa, setPessoa] = useState([]);
  const [empresas, setEmpresas] = useState([]);
  const [parceiros, setParceiros] = useState([]);
  const [produtosVinculados, setProdutosVinculados] = useState([]);
  const [key, setKey] = useState("editarPessoa");
  const [cep, setCep] = useState("");
  const [produtos, setProdutos] = useState([]);
  const [produtoSelecionado, setProdutoSelecionado] = useState("");
  const [produtoARemover, setProdutoARemover] = useState([]);

  const {
    handleSubmit,
    formState: { errors },
  } = useForm();

  const customToastOptions = {
    position: "bottom-right", // Posição onde as notificações serão exibidas
    autoClose: 3000, // Tempo em milissegundos para as notificações fecharem automaticamente
    hideProgressBar: false, // Mostrar barra de progresso de tempo
    pauseOnHover: true, // Pausar o tempo de fechamento ao passar o mouse sobre a notificação
    draggable: true, // Permitir arrastar as notificações
    progress: undefined, // Componente customizado para barra de progresso, caso queira substituir
  };

  const editar = () => {
    BaseAPI.patch("/clientes/pessoa/" + props.idPessoa + "/", pessoa)
      .then((response) => {
        toast.success("Pessoa editada!", customToastOptions);
      })
      .catch((err) => {
        toast.error("Erro ao editar pessoa!", customToastOptions);
      });
  };

  function getPessoa() {
    handleShow();
    BaseAPI.get("/clientes/pessoa/" + props.idPessoa)
      .then((response) => {
        const { data } = response;
        setPessoa(data);
      })
      .catch((err) => {
        alert(err);
      });
  }

  const getEmpresas = (values) => {
    BaseAPI.get("/clientes/select_empresas/", {
      params: {
        nome: values ? values.query : null,
      },
    })
      .then((response) => {
        const { data } = response;
        setEmpresas(data);
      })
      .catch((err) => {
        alert(err);
      });
  };

  const getParceiros = () => {
    handleShow();
    BaseAPI.get("/parceiros/select_parceiros/")
      .then((response) => {
        const { data } = response;
        setParceiros(data);
      })
      .catch((err) => {
        alert(err);
      });
  };

  const getProdutosVinculados = () => {
    BaseAPI.get("/produtos/produto_cliente/", {
      params: {
        id_pessoa: props.idPessoa,
      },
    })
      .then((response) => {
        const { data } = response;
        setProdutosVinculados(data.results);
      })
      .catch((err) => {
        alert(err);
      });
  };

  const buscaCep = (event) => {
    const novoCep = event.target.value;
    setCep(novoCep);
    if (novoCep.length === 8) {
      axios
        .get(`https://viacep.com.br/ws/${novoCep}/json`)
        .then((response) => {
          setPessoa({
            ...pessoa,
            endereco_cep: novoCep,
            endereco_rua: response.data.logradouro,
            endereco_bairro: response.data.bairro,
            endereco_cidade: response.data.localidade,
            endereco_uf: response.data.uf,
          });
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const getProdutos = () => {
    BaseAPI.get("/produtos/select_produtos/")
      .then((response) => {
        const { data } = response;
        setProdutos(data);
      })
      .catch((err) => {
        alert(err);
      });
  };

  const adicionarProdutoClienteArray = () => {
    const produtoSelecionadoNome = produtos.find(
      (produto) => produto.value === parseInt(produtoSelecionado)
    )?.label;

    const produtoSelecionadoStatus = produtos.find(
      (produto) => produto.value === parseInt(produtoSelecionado)
    )?.status;

    if (!produtoSelecionado) {
      toast.error("Selecione um produto!", customToastOptions);
    } else if (
      produtosVinculados.some(
        (produto) => produto.produto === parseInt(produtoSelecionado)
      )
    ) {
      toast.error("Produto já foi adicionado!", customToastOptions);
    } else {
      setProdutosVinculados([
        ...produtosVinculados,
        {
          produto: parseInt(produtoSelecionado),
          nome: produtoSelecionadoNome,
          status: produtoSelecionadoStatus,
        },
      ]);
      toast.success("Produto adicionado!", customToastOptions);
    }
  };

  const removerProdutoClienteArray = (id) => {
    setProdutosVinculados((prevProdutos) =>
      prevProdutos.filter((produto) => produto.produto !== id)
    );
    setProdutoARemover([...produtoARemover, { id: parseInt(id) }]);
  };

  const editarProdutoCliente = () => {
    const produtos = produtosVinculados.map((produto) => ({
      id: produto.produto,
      status: produto.status,
    }));
    BaseAPI.post("/produtos/editar_produto_cliente/" + props.idPessoa + "/", {
      produtos: produtos,
      aRemover: produtoARemover,
    })
      .then((response) => {
        handleClose();
      })
      .catch((err) => {
        alert(err);
      });
  };

  function findLabelByValue(value) {
    for (let i = 0; i < empresas.length; i++) {
      if (empresas[i].value === value) {
        return empresas[i].label;
      }
    }
    return ""; // Se o valor não for encontrado, retorna null ou outra indicação de não encontrado
  }

  useEffect(() => {
    if (show) {
      getEmpresas();
      getParceiros();
      getProdutosVinculados();
      getProdutos();
    }
  }, [show]);
  return (
    <>
      <Button
        variant="warning"
        onClick={() => getPessoa()}
        title="Abrir cadastro do pessoa"
        className="p-1"
      >
        <EditIcon />
      </Button>

      <Modal show={show} onHide={handleClose} centered size="xl">
        <Modal.Header closeButton>
          <Modal.Title>Cadastro da pessoa </Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <Tabs
            id="controlled-tab"
            activeKey={key}
            onSelect={(k) => setKey(k)}
            className="mb-3"
          >
            <Tab eventKey="editarPessoa" title="Cadastro">
          <Form onSubmit={handleSubmit(editar)}>
            <Row>
              <Col>
                <FloatingLabel
                  controlId="floatingInput"
                  label="Nome*"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    maxLength={100}
                    required
                    value={pessoa.nome}
                    onChange={(e) =>
                      setPessoa({ ...pessoa, nome: e.target.value })
                    }
                  />
                </FloatingLabel>
              </Col>
              <Col>
                <FloatingLabel
                  controlId="floatingInput"
                  label="CPF*"
                  className="mb-3"
                  required
                >
                  <InputMask
                    mask="999.999.999-99"
                    maskChar="_"
                    maxLength={14}
                    value={pessoa.cpf}
                    onChange={(e) =>
                      setPessoa({
                        ...pessoa,
                        cpf: e.target.value,
                      })
                    }
                  >
                    {() => (
                      <Form.Control
                        type="text"
                      />
                    )}
                  </InputMask>
                </FloatingLabel>
              </Col>
            </Row>
            <Row>
              <Col>
                <FloatingLabel
                  controlId="floatingInput"
                  label="Data de nascimento*"
                  className="mb-3"
                >
                  <Form.Control
                    type="date"
                    required
                    min="1500-01-01"
                    max="9998-12-31"
                    value={pessoa.data_nascimento}
                    onChange={(e) =>
                      setPessoa({
                        ...pessoa,
                        data_nascimento: e.target.value,
                      })
                    }
                  />
                </FloatingLabel>
              </Col>
              <Col>
                <FloatingLabel
                  controlId="floatingInput"
                  label="Telefone*"
                  className="mb-3"
                  required
                >
                  <Form.Control
                    type="text"
                    maxLength={20}
                    value={pessoa.telefone}
                    onChange={(e) =>
                      setPessoa({
                        ...pessoa,
                        telefone: e.target.value,
                      })
                    }
                  />
                </FloatingLabel>
              </Col>
              <Col>
                <FloatingLabel
                  controlId="floatingInput"
                  label="E-mail*"
                  className="mb-3"
                  required
                >
                  <Form.Control
                    type="text"
                    maxLength={100}
                    value={pessoa.email}
                    onChange={(e) =>
                      setPessoa({
                        ...pessoa,
                        email: e.target.value,
                      })
                    }
                  />
                </FloatingLabel>
              </Col>
            </Row>
            <Row>
              <Col>
                <FloatingLabel
                  controlId="cepInput"
                  label="CEP"
                  htmlFor="cepInput"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    maxLength={8}
                    value={pessoa.endereco_cep}
                    onChange={(e) => {
                      setPessoa({
                        ...pessoa,
                        endereco_cep: e.target.value,
                      });
                      buscaCep(e);
                    }}
                  />
                </FloatingLabel>
              </Col>
              <Col>
                <FloatingLabel
                  controlId="floatingInput"
                  label="Rua"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    maxLength={256}
                    value={pessoa.endereco_rua}
                    onChange={(e) =>
                      setPessoa({
                        ...pessoa,
                        endereco_rua: e.target.value,
                      })
                    }
                  />
                </FloatingLabel>
              </Col>
              <Col>
                <FloatingLabel
                  controlId="floatingInput"
                  label="N°"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    maxLength={10}
                    value={pessoa.endereco_numero}
                    onChange={(e) =>
                      setPessoa({
                        ...pessoa,
                        endereco_numero: e.target.value,
                      })
                    }
                  />
                </FloatingLabel>
              </Col>
            </Row>
            <Row>
              <Col>
                <FloatingLabel
                  controlId="floatingInput"
                  label="Bairro"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    maxLength={256}
                    value={pessoa.endereco_bairro}
                    onChange={(e) =>
                      setPessoa({
                        ...pessoa,
                        endereco_bairro: e.target.value,
                      })
                    }
                  />
                </FloatingLabel>
              </Col>
              <Col>
                <FloatingLabel
                  controlId="floatingInput"
                  label="Cidade"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    maxLength={256}
                    value={pessoa.endereco_cidade}
                    onChange={(e) =>
                      setPessoa({
                        ...pessoa,
                        endereco_cidade: e.target.value,
                      })
                    }
                  />
                </FloatingLabel>
              </Col>
              <Col>
                <FloatingLabel
                  controlId="floatingInput"
                  label="UF"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    maxLength={2}
                    value={pessoa.endereco_uf}
                    onChange={(e) =>
                      setPessoa({
                        ...pessoa,
                        endereco_uf: e.target.value,
                      })
                    }
                  />
                </FloatingLabel>
              </Col>
            </Row>
            <Row>
              <Col>
                <FloatingLabel
                  controlId="floatingInput"
                  label="Descrição"
                  className="mb-3"
                  required
                >
                  <Form.Control
                    type="text"
                    value={pessoa.descricao}
                    onChange={(e) =>
                      setPessoa({
                        ...pessoa,
                        descricao: e.target.value,
                      })
                    }
                  />
                </FloatingLabel>
              </Col>
              <Col>
                <Select
                  inputId="empresa"
                  defaultInputValue={findLabelByValue(pessoa.empresa)}
                  options={empresas}
                  name="empresa"
                  placeholder="Empresa"
                  isSearchable
                  onChange={(e) => {
                      setPessoa({
                        ...pessoa,
                        empresa: e.value,
                      })
                    }
                  }
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <FloatingLabel
                  controlId="parceiros"
                  label="Parceiro"
                  className="mb-3"
                >
                  <Form.Select
                    type="text"
                    value={pessoa.parceiro}
                    onChange={(e) =>
                      setPessoa({
                        ...pessoa,
                        parceiro: e.target.value,
                      })
                    }
                  >
                    <option value="">Selecione...</option>

                    {parceiros.length > 0 &&
                      parceiros.map((parceiro) => {
                        return (
                          <option
                            key={parceiro.value}
                            value={parceiro.value}
                          >
                            {parceiro.label}
                          </option>
                        );
                      })}
                  </Form.Select>
                </FloatingLabel>
              </Col>
            </Row>
          </Form>
        </Tab>
        <Tab eventKey="produtosPessoa" title="Produtos">
          <Form>
            <Row>
              <Col md={10}>
                <FloatingLabel
                  controlId="produtos"
                  label="Produtos"
                  className="mb-3"
                >
                  <Form.Select
                    aria-label="Produtos"
                    value={produtoSelecionado}
                    onChange={(e) => setProdutoSelecionado(e.target.value)}
                  >
                    <option value="">Selecione...</option>
                    {produtos.length > 0 &&
                      produtos.map((produto) => {
                        return (
                          <option
                            key={produto.value}
                            value={produto.value}
                            name={produto.label}
                            id={"produto_" + produto.value}
                          >
                            {produto.label}
                          </option>
                        );
                      })}
                  </Form.Select>
                </FloatingLabel>
              </Col>
              <Col className="pt-2">
                <Button onClick={() => adicionarProdutoClienteArray()}>
                  Adicionar Produto
                </Button>
              </Col>
            </Row>
          </Form>
          <p className="text-danger">
            Os dados só serão salvos após clicar em Salvar produtos.
          </p>
          <Table striped responsive bordered>
            <thead>
              <tr className="text-center">
                <th>Produto</th>
                <th>Status</th>
                <th>Ação</th>
              </tr>
            </thead>
            <tbody className="text-center">
              {produtosVinculados.length > 0 &&
                produtosVinculados.map((produtoVinculado, index) => {
                  const produto = produtos.find(
                    (p) => p.value === produtoVinculado.produto
                  );

                  return (
                    <tr
                      key={index}
                      value={produtoVinculado.id}
                      className="align-middle"
                    >
                      <td value={produtoVinculado.id}>
                        {produto ? produto.label : "Produto não encontrado"}
                      </td>
                      <td>
                        <FloatingLabel
                          controlId={`status_${index}`}
                          label="Status"
                        >
                          <Form.Select
                            aria-label="Status"
                            key={produtosVinculados.id}
                            value={produtoVinculado.status}
                            onChange={(e) => {
                              const newProdutosVinculados = [
                                ...produtosVinculados,
                              ];
                              newProdutosVinculados[index].status =
                                e.target.value;
                              setProdutosVinculados(newProdutosVinculados);
                            }}
                          >
                            <option value="A">Ativo</option>
                            <option value="C">Cancelado</option>
                            <option value="V">Vencido</option>
                          </Form.Select>
                        </FloatingLabel>
                      </td>
                      <td>
                        <Button
                          variant="danger"
                          onClick={() =>
                            removerProdutoClienteArray(
                              produtoVinculado.produto
                            )
                          }
                        >
                          Remover
                        </Button>
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </Table>
          <Row>
            <Col>
              <Button variant="success" onClick={() => editarProdutoCliente()}>
                Salvar produtos
              </Button>
            </Col>
          </Row>
        </Tab>
      </Tabs>
    </Modal.Body>
    <Modal.Footer>
      <Button variant="danger" onClick={handleClose}>
        Cancelar
      </Button>
      <Button variant="success" onClick={handleSubmit(editar)}>
        Editar
      </Button>
    </Modal.Footer>
  </Modal>
      <ToastContainer />
    </>
  );
}

export { ModalEditarPessoa };
export default ModalEditarPessoa;
