import axios from "axios";

const NextAndPreviousPageAPI = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  withCredentials: true,
  headers: {
    "Content-Type": "application/json",
    "X-Requested-With": "XMLHttpRequest",
  },
});

const access = JSON.parse(localStorage.getItem("access"));

if (access) {
  NextAndPreviousPageAPI.defaults.headers.common["Authorization"] = `Bearer ${access}`;
}

export default NextAndPreviousPageAPI;
