import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import {
  Breadcrumb,
  Button,
  Container,
  Row,
  Col,
  Form,
  FloatingLabel,
} from "react-bootstrap";
import InputMask from 'react-input-mask';

import BaseAPI from "../../../api/BaseAPI";
import LocalPrintshopIcon from "@mui/icons-material/LocalPrintshop";

function RelatorioRelacaoClientes() {
  const [carregando, setCarregando] = useState(true);
  const [nomeRelatorio, setNomeRelatorio] = useState("");



  const { handleSubmit } = useForm({
    defaultValues: {
      query: "",
    },
  });

  const getRelatorio = () => {
    setCarregando(true);
    BaseAPI.get(`/relatorios/${nomeRelatorio}/`, {
    })
      .then((response) => {
        setCarregando(false);
        window.open(response.request.responseURL, "_blank");
      })
      .catch((err) => {
        alert(err);
      });
  };

  const nomeDoRelatorio = () => {
    const urlAtual = new URL(window.location.href);
    const nomeDoRelatorio = urlAtual.pathname.substring(12);
    setNomeRelatorio(nomeDoRelatorio);

    return nomeDoRelatorio;
  };

  useEffect(() => {
    nomeDoRelatorio();
  }, []);

  return (
    <Container>
      <Breadcrumb>
        <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
        <Breadcrumb.Item href="/relatorios">Relatórios</Breadcrumb.Item>
        <Breadcrumb.Item active>Clientes por produto</Breadcrumb.Item>
      </Breadcrumb>
      <form onSubmit={handleSubmit(getRelatorio)}>
        <Row className="my-4">
          <Col sm className="mt-2">
            <Button className="mx-1" onClick={handleSubmit(getRelatorio)}>
              <LocalPrintshopIcon />
            </Button>
          </Col>
        </Row>
      </form>
    </Container>
  );
}

export { RelatorioRelacaoClientes };
export default RelatorioRelacaoClientes;
