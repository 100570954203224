import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import {
  Breadcrumb,
  Button,
  Container,
  Row,
  Col,
  Table,
  Spinner,
  Form,
  FloatingLabel,
} from "react-bootstrap";
import { useNavigate } from "react-router-dom";


import ManageSearchIcon from "@mui/icons-material/ManageSearch";
import BaseAPI from "../../../api/BaseAPI";
import NextAndPreviousPageAPI from "../../../api/NextAndPreviousPageAPI";
import RenderIf from "../../../design_system/RenderIf";

function ListaRelatorios() {
  const [relatorios, setRelatorios] = useState([{}]);
  const [carregando, setCarregando] = useState(true);
  const navigate = useNavigate();

  const { register, handleSubmit } = useForm({
    defaultValues: {
      query: "",
    },
  });

  const getRelatorios = (values) => {
    setCarregando(true);
    BaseAPI.get("/relatorios/lista_relatorios/", {
      params: {
        nome: values ? values.query : null,
      },
    })
      .then((response) => {
        const { data } = response;
        setRelatorios(data);
        setCarregando(false);
      })
      .catch((err) => {
        alert(err);
      });
  };

  const nextPage = () => {
    const url = new URL(relatorios.next);
    const path = url.pathname.substring(7) + url.search;
    NextAndPreviousPageAPI.get(path)
      .then((response) => {
        const { data } = response;
        setRelatorios(data);
        setCarregando(false);
      })
      .catch((err) => {
        alert(err);
      });
  };
  const previousPage = () => {
    const url = new URL(relatorios.previous);
    const path = url.pathname.substring(7) + url.search;
    NextAndPreviousPageAPI.get(path)
      .then((response) => {
        const { data } = response;
        setRelatorios(data);
        setCarregando(false);
      })
      .catch((err) => {
        alert(err);
      });
  };

  const irParaRelatorio = (chave) => {
    navigate(`relatorios/${chave}`);
  };

  useEffect(() => {
    getRelatorios();
  }, []);

  return (
    <Container>
      <Breadcrumb>
        <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
        <Breadcrumb.Item active>Relatórios</Breadcrumb.Item>
      </Breadcrumb>
      <form onSubmit={handleSubmit(getRelatorios)}>
        <Row className="my-4">
          <Col sm>
            <FloatingLabel
              controlId="floatingInput"
              label="Relatório"
              className="mb-3"
            >
              <Form.Control type="text" {...register("query")} />
            </FloatingLabel>
          </Col>
          <Col sm className="mt-2">
            <Button className="mx-1">
              <ManageSearchIcon onClick={handleSubmit(getRelatorios)} />
            </Button>
          </Col>
        </Row>
      </form>

      <Table striped responsive bordered>
        <thead>
          <tr className="text-center">
            <th>Relatório</th>
          </tr>
        </thead>
        <tbody className="text-center">
          {carregando && <Spinner animation="border" variant="primary" />}
          {!carregando &&
            relatorios.results.length > 0 &&
            relatorios.results.map((relatorio) => {
              return (
                <tr key={relatorio.id}>
                  <td
                    className="cursor-pointer"
                    onClick={() => {
                      navigate(`${relatorio.chave}`);
                      ;
                    }}
                  >
                    {relatorio.nome}
                  </td>
                </tr>
              );
            })}
        </tbody>
      </Table>
      <div className="d-flex justify-content-center">
        <RenderIf condicao={relatorios.previous}>
          <div className="m-1">
            <Button variant="primary" onClick={previousPage}>
              &lt;
            </Button>
          </div>
        </RenderIf>

        <RenderIf condicao={relatorios.next}>
          <div className="m-1">
            <Button variant="primary" onClick={nextPage}>
              &gt;
            </Button>
          </div>
        </RenderIf>
      </div>
    </Container>
  );
}

export { ListaRelatorios };
export default ListaRelatorios;
