import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import {
  Breadcrumb,
  Button,
  Container,
  Row,
  Col,
  Form,
  FloatingLabel,
} from "react-bootstrap";
import Select from 'react-select'

import BaseAPI from "../../../api/BaseAPI";
import LocalPrintshopIcon from "@mui/icons-material/LocalPrintshop";

function ListagemProdutoCliente() {
  const [carregando, setCarregando] = useState(true);
  const [nomeRelatorio, setNomeRelatorio] = useState("");
  const [nome, setNomeCliente] = useState("");
  const [tipo, setTipo] = useState(null);
  const [produto, setProduto] = useState("");
  const [produtos, setProdutos] = useState([]);

  const { handleSubmit } = useForm({
    defaultValues: {
      query: "",
    },
  });

  const getRelatorio = () => {
    setCarregando(true);
    BaseAPI.get(`/relatorios/${nomeRelatorio}/`, {
      params: { produto, nome, tipo },
    })
      .then((response) => {
        setCarregando(false);
        window.open(response.request.responseURL, "_blank");
      })
      .catch((err) => {
        alert(err);
      });
  };

  const nomeDoRelatorio = () => {
    const urlAtual = new URL(window.location.href);
    const nomeDoRelatorio = urlAtual.pathname.substring(12);
    setNomeRelatorio(nomeDoRelatorio);

    return nomeDoRelatorio;
  };

  const getProdutos = () => {
    BaseAPI.get("/produtos/select_produtos/")
      .then((response) => {
        const { data } = response;
        setProdutos(data);
      })
      .catch((err) => {
        alert(err);
      });
  };

  useEffect(() => {
    nomeDoRelatorio();
    getProdutos();
  }, []);

  return (
    <Container>
      <Breadcrumb>
        <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
        <Breadcrumb.Item href="/relatorios">Relatórios</Breadcrumb.Item>
        <Breadcrumb.Item active>
          Listagem de Produtos por Cliente
        </Breadcrumb.Item>
      </Breadcrumb>
      <form onSubmit={handleSubmit(getRelatorio)}>
        <Row className="my-4">
          <Col sm>
            <Select 
                inputId="produto" 
                options={produtos}
                name="produto"
                defaultValue={null}
                placeholder="Selecione..."
                isClearable
                isSearchable
                onChange={(e) => {
                  if (e) {
                    setProduto(e.value)
                  }
                }}
              />
          </Col>
          <Col sm>
            <FloatingLabel
              controlId="floatingInput"
              label="Nome do Cliente"
              className="mb-3"
            >
              <Form.Control
                type="text"
                value={nome}
                onChange={(e) => setNomeCliente(e.target.value)}
              />
            </FloatingLabel>
          </Col>

          <Col>
            <FloatingLabel
              controlId="tipoCliente"
              label="Tipo do cliente"
              className="mb-3"
            >
              <Form.Select
                aria-label="Tipo do cliente"
                required
                onChange={(e) => setTipo(e.target.value)}
              >
                <option value={null}>Ambos</option>
                <option value="pf">Pessoa física</option>
                <option value="pj">Pessoa jurídica</option>
              </Form.Select>
            </FloatingLabel>
          </Col>
          <Col sm className="mt-2">
            <Button className="mx-1" onClick={handleSubmit(getRelatorio)}>
              <LocalPrintshopIcon />
            </Button>
          </Col>
        </Row>
      </form>
    </Container>
  );
}

export { ListagemProdutoCliente };
export default ListagemProdutoCliente;
